
























































import { Vue, Component } from "vue-property-decorator";
import Rview from "@/components/routerView/index.vue";
import Bread from "@/components/bread/index.vue";
import { OtherModule } from "@/store/other";
@Component({
  components: {
    Rview,
    Bread,
  },
})
export default class extends Vue {
  get is_mobile() {
    return OtherModule.is_mobile;
  }
}
